import './PreTestDisplay.css'

import React, { Fragment, useEffect, useRef, useState } from 'react'

import Button from '../Button'
import { useMediaStreams } from '../MediaStreamRecorder'

import bulb from './bulb.svg'

const PreTestDisplay = props => {
  const { next } = props
  // const videoRef = useRef()
  const mediaStreams = useMediaStreams()

  useEffect(() => {
    async function connectVideo() {
      if (navigator.mediaDevices.getDisplayMedia) {
        try {
          const stream = await navigator.mediaDevices.getDisplayMedia({
            video: {
              cursor: {
                ideal: 'always'
              },
              displaySurface: {
                exact: 'monitor'
              },
              frameRate: {
                ideal: 5,
                max: 60
              },
              logicalSurface: {
                ideal: false
              }
            }
          })
          // videoRef.current.srcObject = stream
          mediaStreams.setup('display', stream)
          next()
        } catch (err) {
          console.error(err)
          alert('Oops, something went wrong. Please refresh and try again')
        }
      }
    }

    connectVideo()
  }, [])

  return (
    <Fragment>
      {/* <video className="k-screen" autoPlay ref={videoRef}/> */}
      <div className="k-screen-msg">
        <img className="k-screen-msg__icon" src={bulb} alt=""/>
        <p className="k-screen-msg__intro">We need to you to share your screen, so that we can record your test.</p>
        <div className="k-screen-msg__title">Step one</div>
        <p>In the pop-up that has appeared, click the small version of this window.</p>
        <div className="k-screen-msg__title">Step two</div>
        <p>Click the button that says “Share”.</p>
      </div>
    </Fragment>
  )
}

export default PreTestDisplay
