import './ABTest.css'
import ripples from './ripples.svg'

import React, { useState, useEffect } from 'react'
import YouTube from 'react-youtube'

import { useMediaStreams } from '../MediaStreamRecorder'

const ABTest = (props) => {
  const { open, uploader } = props
  const mediaStreams = useMediaStreams()
  
  const [showGap, setShowGap] = useState(false)
  const [showVidA, setShowVidA] = useState(false)
  const [showVidB, setShowVidB] = useState(false)
  const [seqStarted, setSeqStarted] = useState(false)

  const videoAId = 'cUem3au7lo4'
  const videoBId = 'NnMfIzEA5Z4'

  const videoOptions = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      disablekb: 1, // disable keyboard control
      fs: 0, // fullscreen
      iv_load_policy: 3, // dont show video annotations
      loop: 0,
      modestbranding: 1,
      playsinline: 1,
      rel: 0 // dont show external related videos (channel related still shown)
    }
  }

  useEffect(() => {
    if (open && !seqStarted) {
      mediaStreams.record(uploader)
      startSequence()
      setSeqStarted(true)
    }
  }, [open])

  const startSequence = () => {
    setShowGap(true)
    
    setTimeout(() => {
      setShowGap(false)
      setShowVidA(true)
    }, 3000)
  }

  const onVidAEnd = () => {
    setShowVidA(false)
    setShowGap(true)
    
    setTimeout(() => {
      setShowGap(false)
      setShowVidB(true)
    }, 3000)
  }

  const onVidBEnd = () => {
    setShowVidB(false)
    mediaStreams.stop()
    props.onComplete()
  }

  return (
      <div className="k-ab-test" data-open={open}>
          <div className="k-ab-test-gap" data-open={showGap}>
            <img className="k-ab-test-ripples" src={ripples} alt=""/>
          </div>
          {showVidA && <YouTube videoId={videoAId} opts={videoOptions} onEnd={onVidAEnd}></YouTube>}
          {showVidB && <YouTube videoId={videoBId} opts={videoOptions} onEnd={onVidBEnd}></YouTube>}
      </div>
  )
}

export default ABTest
